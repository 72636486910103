<template>
  <home-buttons :buttons="buttons" @selected="selected" />
  <projects-list
    :searchInputValue="searchInputValue"
    @modalEditOpen="(project) => $emit('modalEditOpen', project)"
    @searchError="(error) => $emit('searchError', error)"
  />
</template>

<script>
import ProjectsList from "@/components/ProjectsList/ProjectsList.vue";
import HomeButtons from "@/components/HomeButtons/HomeButtons.vue";

import { getUrlData } from "@/Hooks/getUrlData";
import { updateProjects } from "@/Hooks/updateProjects";

export default {
  name: "HomeContent",

  components: {
    ProjectsList,
    HomeButtons,
  },

  props: ["searchInputValue"],

  emits: ["modalEditOpen"],

  data() {
    return {
      buttons: [
        {
          id: 0,
          title: "Последние работы",
          icon: {
            svg: {
              width: 28,
              height: 28,
              path: {
                d: "M13.9165 0.5C21.382 0.5 27.4165 6.548 27.4165 14C27.4165 21.4655 21.382 27.5 13.9165 27.5C6.4645 27.5 0.416504 21.4655 0.416504 14C0.416504 6.548 6.4645 0.5 13.9165 0.5ZM13.444 7.1555C12.8905 7.1555 12.4315 7.601 12.4315 8.168V14.9855C12.4315 15.3365 12.6205 15.6605 12.931 15.8495L18.223 19.0085C18.385 19.103 18.5605 19.157 18.7495 19.157C19.087 19.157 19.4245 18.9815 19.6135 18.6575C19.897 18.185 19.7485 17.564 19.2625 17.267L14.4565 14.405V8.168C14.4565 7.601 13.9975 7.1555 13.444 7.1555Z",
              },
            },
          },
          active: true,
          filterId: -1,
        },
        {
          id: 1,
          title: "Дизайн",
          icon: {
            svg: {
              width: 28,
              height: 28,
              path: {
                d: "M19.6007 0.5C24.1762 0.5 27.25 3.71045 27.25 8.48757V19.5124C27.25 24.2895 24.1762 27.5 19.5993 27.5H7.89935C3.32381 27.5 0.25 24.2895 0.25 19.5124V8.48757C0.25 3.71045 3.32381 0.5 7.89935 0.5H19.6007ZM21.0894 14.7426C19.6423 13.8398 18.525 15.1076 18.2237 15.513C17.9331 15.9045 17.6834 16.3364 17.42 16.7683C16.7765 17.8341 16.0394 19.0629 14.7633 19.7776C12.9089 20.8043 11.5012 19.8584 10.4885 19.1701C10.1084 18.9138 9.7391 18.6714 9.37121 18.5098C8.46439 18.1183 7.64851 18.5641 6.43759 20.1022C5.80227 20.906 5.17238 21.7029 4.53434 22.4971C4.15287 22.9721 4.24383 23.705 4.75833 24.0226C5.57964 24.5283 6.58149 24.8 7.71367 24.8H19.0911C19.7332 24.8 20.3767 24.7122 20.9903 24.5116C22.3723 24.0602 23.4692 23.0265 24.042 21.6611C24.5253 20.5132 24.7602 19.12 24.3081 17.9608C24.1574 17.5763 23.9321 17.2183 23.6158 16.9034C22.7863 16.08 22.0112 15.311 21.0894 14.7426ZM9.02346 5.9C7.16229 5.9 5.65 7.41434 5.65 9.275C5.65 11.1357 7.16229 12.65 9.02346 12.65C10.8833 12.65 12.3969 11.1357 12.3969 9.275C12.3969 7.41434 10.8833 5.9 9.02346 5.9Z",
              },
            },
          },
          active: false,
          filterId: 7,
        },
        {
          id: 2,
          title: "Иллюстрация",
          icon: {
            svg: {
              width: 28,
              height: 28,
              path: {
                d: "M20.3812 0.513953C22.3232 0.392578 24.2382 1.06688 25.6812 2.38851C27.0029 3.83152 27.6772 5.74654 27.5693 7.70202V20.298C27.6907 22.2535 27.0029 24.1685 25.6947 25.6115C24.2517 26.9331 22.3232 27.6074 20.3812 27.486H7.78508C5.82959 27.6074 3.91456 26.9331 2.47154 25.6115C1.14989 24.1685 0.475585 22.2535 0.596961 20.298V7.70202C0.475585 5.74654 1.14989 3.83152 2.47154 2.38851C3.91456 1.06688 5.82959 0.392578 7.78508 0.513953H20.3812ZM20.0305 6.67708C19.1944 5.84094 17.8458 5.84094 17.0096 6.67708L16.106 7.59413C15.9712 7.72899 15.9712 7.95825 16.106 8.09311C16.106 8.09311 16.1325 8.11942 16.1796 8.16626L16.5114 8.49617C16.7032 8.68708 16.9427 8.9255 17.1832 9.16525L17.9988 9.98038C18.1689 10.1513 18.2818 10.2659 18.2908 10.2779C18.4391 10.4397 18.5336 10.6555 18.5336 10.8982C18.5336 11.3837 18.1425 11.7883 17.6435 11.7883C17.4142 11.7883 17.1984 11.6939 17.0501 11.5455L14.7979 9.30686C14.69 9.19897 14.5012 9.19897 14.3933 9.30686L7.9604 15.7397C7.51536 16.1847 7.25912 16.7781 7.24564 17.412L7.16472 20.6082C7.16472 20.7835 7.21866 20.9453 7.34004 21.0667C7.46141 21.1881 7.62325 21.2555 7.79857 21.2555H10.9678C11.6152 21.2555 12.2355 20.9993 12.7075 20.5407L21.7837 11.4377C22.6064 10.6015 22.6064 9.25291 21.7837 8.43026L20.0305 6.67708Z",
              },
            },
          },
          active: false,
          filterId: 13,
        },
        {
          id: 3,
          title: "Видео",
          icon: {
            svg: {
              width: 28,
              height: 22,
              path: {
                d: "M14.2884 0.875C17.5581 0.875 19.841 3.1282 19.841 6.35723V15.6428C19.841 18.8718 17.5581 21.125 14.2884 21.125H6.46911C3.19938 21.125 0.916504 18.8718 0.916504 15.6428V6.35723C0.916504 3.1282 3.19938 0.875 6.46911 0.875H14.2884ZM25.1598 4.08663C25.7524 3.78511 26.4477 3.81663 27.0133 4.17297C27.579 4.52795 27.9165 5.1447 27.9165 5.81902V16.1818C27.9165 16.8575 27.579 17.4729 27.0133 17.8278C26.7042 18.0211 26.3586 18.1198 26.0103 18.1198C25.72 18.1198 25.4298 18.0512 25.1584 17.9128L23.1591 16.9041C22.4193 16.5286 21.9602 15.7748 21.9602 14.9373V7.06211C21.9602 6.22333 22.4193 5.46952 23.1591 5.09673L25.1598 4.08663Z",
              },
            },
          },
          active: false,
          filterId: 12,
        },
        {
          id: 4,
          title: "Анимация",
          icon: {
            svg: {
              width: 28,
              height: 28,
              path: {
                d: "M13.7498 0.5C21.1904 0.5 27.2498 6.55644 27.2498 14.0078C27.2498 21.4436 21.1904 27.5 13.7498 27.5C6.30912 27.5 0.249756 21.4436 0.249756 14.0078C0.249756 6.55644 6.30912 0.5 13.7498 0.5ZM12.2121 8.64038C11.9254 8.64038 11.6518 8.7055 11.3912 8.83575C11.0654 9.01809 10.8048 9.30463 10.6614 9.64327C10.5702 9.87771 10.4269 10.581 10.4269 10.5941C10.2835 11.3625 10.2054 12.6129 10.2054 13.9935C10.2054 15.3103 10.2835 16.5072 10.4008 17.2887C10.4138 17.3017 10.5572 18.1744 10.7136 18.474C11.0002 19.021 11.5606 19.3596 12.16 19.3596H12.2121C12.603 19.3466 13.424 19.008 13.424 18.9949C14.8053 18.4219 17.5287 16.6375 18.6233 15.4522L18.7015 15.3741C18.8448 15.2308 19.0273 15.0094 19.0664 14.9573C19.2749 14.6838 19.3791 14.3452 19.3791 14.0078C19.3791 13.6288 19.2618 13.2771 19.0403 12.9906C18.9882 12.9385 18.7927 12.7171 18.6103 12.5347C17.5417 11.3886 14.7531 9.51303 13.2937 8.93994C13.0722 8.85007 12.5118 8.6534 12.2121 8.64038Z",
              },
            },
          },
          active: false,
          filterId: 14,
        },
        {
          id: 5,
          title: "Программирование",
          icon: {
            svg: {
              width: 28,
              height: 28,
              path: {
                d: "M25.6815 2.38851C24.2384 1.06688 22.3234 0.392578 20.3814 0.513953H7.78533C5.82983 0.392578 3.9148 1.06688 2.47178 2.38851C1.15014 3.83152 0.475829 5.74654 0.597205 7.70202V20.298C0.475829 22.2535 1.15014 24.1685 2.47178 25.6115C3.9148 26.9331 5.82983 27.6074 7.78533 27.486H20.3814C22.3234 27.6074 24.2519 26.9331 25.6949 25.6115C27.0031 24.1685 27.6909 22.2535 27.5695 20.298V7.70202C27.6774 5.74654 27.0031 3.83152 25.6815 2.38851ZM16.4721 8.99741C16.5983 8.54882 16.3369 8.08289 15.8884 7.95673C15.4398 7.83056 14.9738 8.09194 14.8477 8.54052L11.8102 19.3405C11.684 19.7891 11.9454 20.255 12.394 20.3812C12.8426 20.5074 13.3085 20.246 13.4346 19.7974L16.4721 8.99741ZM9.84403 10.7156C10.1735 10.3861 10.1735 9.85185 9.84403 9.52234C9.51452 9.19284 8.98029 9.19284 8.65079 9.52234L5.07808 13.095C4.48498 13.6882 4.48498 14.6498 5.07808 15.2429L8.65079 18.8156C8.98029 19.1451 9.51452 19.1451 9.84403 18.8156C10.1735 18.4861 10.1735 17.9518 9.84403 17.6223L6.39065 14.169L9.84403 10.7156ZM19.6315 9.52234C19.302 9.19284 18.7678 9.19284 18.4383 9.52234C18.1088 9.85185 18.1088 10.3861 18.4383 10.7156L21.8917 14.169L18.4383 17.6223C18.1088 17.9518 18.1088 18.4861 18.4383 18.8156C18.7678 19.1451 19.302 19.1451 19.6315 18.8156L23.2042 15.2429C23.7973 14.6498 23.7973 13.6882 23.2042 13.095L19.6315 9.52234Z",
              },
            },
          },
          active: false,
          filterId: 15,
        },
      ],
    };
  },

  computed: {
    filterId() {
      return this.buttons.find((button) => button.active === true).filterId;
    },
  },

  methods: {
    setCurrentButton(index) {
      this.buttons.forEach(
        (button, buttonIndex) => (button.active = buttonIndex === index)
      );
    },

    selected(index) {
      this.setCurrentButton(index);
      localStorage.setItem("currentDirection", this.filterId);

      if (this.filterId !== -1) {
        this.$store.dispatch("getProjectsByCategoryID", {
          categoryID: this.filterId,
        });
        return;
      }

      updateProjects();
    },

    resetButtons() {
      this.buttons.forEach((tab, index) => (tab.active = index === 0));
    },

    updateCurrentDirection() {
      const filterIdFromLS = +localStorage.getItem("currentDirection");
      const urlData = getUrlData();

      if (urlData) {
        this.resetButtons();
      } else if (filterIdFromLS) {
        this.buttons.find(
          (button) => (button.active = button.filterId === filterIdFromLS)
        );
      }
    },
  },

  created() {
    this.updateCurrentDirection();
    // updateProjects();
  },
};
</script>
